import {
  injectGlobal
} from 'styled-components'

injectGlobal`
  .image-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    /* Modifiers
    ------------------------------- */
    &.icon-left-button {
      position: absolute;
      top: 10px;
      left: 15px;
      &.is-order-dealer-selector {
        left: 20px;
      }
    }

    &.is-dealer-card-img {
      border-radius: 5px 5px 0 0;
    }

    &.is-width-auto {
      .image-ratio-base {
        margin: auto;
        width: auto;
        right: 0;
      }
    }

    &.is-full-width {
      padding-bottom: 0;
      .image-ratio-base {
        position: relative;
        width: 100%;
        height: auto;
      }
    }

    /* Fix image circle in iphone safari */
    &.is-circle {
      .image-ratio-base {
        border-radius: 50%;
      }
    }

    /* Media queries
    ------------------------------- */
  }
`
