import {
  injectGlobal
} from 'styled-components'

injectGlobal`
  .table-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    /* Modifiers
    ------------------------------- */
    &.is-modifierName {}

    /* Media queries
    ------------------------------- */
  }
`
