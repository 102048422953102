import { injectGlobal } from "styled-components";
import { CONTENTS } from "../../../themes";
import { default as VARIABLES } from "./../../../themes/styles/bases/variables";

injectGlobal`
  .shape-container-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    /* Modifiers
    ------------------------------- */
    &.is-user-profile {
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_2};
      top: 60px;
      left: 20px;
    }

    &.is-menu-button {
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      bottom: 0;
      left: calc(100% - 15px);
    }

    &.is-badge-thumbnail {
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: -5px;
      right: -7px;

      .text-base {
        font-size: 6px;
      }
    }

    &.is-trueblue-points {
      transform: translate(-50%, -50%);
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_2};
      top: calc(50% + 20px);
      left: 50%;
    }

    &.is-gold-card-header {
      background-color: #D9BC72;
      justify-content: center;
      border-radius: 10px 10px 0 0;
    }

    &.is-gold-card-body {
      border-radius: 0 0 10px 10px;
      overflow: hidden;
    }

    &.is-card-read-term {
      background: url(${CONTENTS["bg-preview-campaign.jpeg"]}), lightgray no-repeat;
      background-position: center top;
      border-radius: 10px;
    }
    /* Media queries
    ------------------------------- */
  }
`;
