import {
  injectGlobal
} from 'styled-components'

injectGlobal`
  .container-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    /* Modifiers
    ------------------------------- */
    &.is-content-detail {
      position: relative;
    }

    &.is-position-relative {
      position: relative;
    }

    &.is-register-success {
      background: rgb(255,255,255);
      background: -moz-linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(25,111,170,1) 75%, rgba(25,111,170,1) 100%);
      background: -webkit-linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(25,111,170,1) 75%, rgba(25,111,170,1) 100%);
      background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(25,111,170,1) 75%, rgba(25,111,170,1) 100%);
    }

    /* Media queries
    ------------------------------- */
  }
`
