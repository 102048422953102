import {
  injectGlobal
} from 'styled-components'
import {
  default as TYPOGRAPHYS
} from './../../../themes/styles/bases/typographys'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'

injectGlobal`
  .accordion-control-base {
    /* Animation
    ------------------------------- */
    @keyframes fade-in {
      0% {
          opacity: 0;
      }

      100% {
          opacity: 1;
      }
    }

    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    /* Container */
    .accordion {
    }

    /* List */
    .accordion__panel {

      &:last-child {}
    }

    /* Button */
    .accordion__heading {

      &:focus {}

      /* Arrow */
      &:before,
      &:after {}

      &:before {}

      &:after {}

      /* Open content */
      &[aria-selected='true'] {
        &:before {}

        &:after {}
      }
    }

    /* Content */
    .accordion__panel {
      &.accordion__panel--hidden {}
    }

    /* Modifiers
    ------------------------------- */
    /* Dropdown */
    &.is-dropdown {

      /* Container */
      .accordion {}

      /* List */
      .accordion__panel {}

      /* Button */
      .accordion__heading {}

      /* Content */
      .accordion__panel {
        transition: ${VARIABLES.TRANSITIONS.DEFAULT};
        transform: translateY(0);
        opacity: 1;

        &[aria-hidden='true'] {
          transform: translateY(15px);
          opacity: 0;
        }
      }
    }

    /* Description */
    &.is-description {
      /* Container */
      .accordion {
        border: none;
      }

      /* List */
      .accordion__panel {
        margin-bottom: 5px;
        border-bottom: none;

        &:last-child {
          margin-bottom: 0;
        }
      }

      /* Button */
      .accordion__heading {
        ${TYPOGRAPHYS.FIRST.REGULAR.FS_14};
        transition: ${VARIABLES.TRANSITIONS.DEFAULT};
        background-color: ${VARIABLES.COLORS.LIGHT_BLUE_1};
        color: ${VARIABLES.COLORS.BLUE_2};
        line-height: 1;

        .accordion__button {
          padding: 13px 80px 13px 35px;
          &:before {
            display: none;
          }
  
          &:after {
            transform: translate(0, -50%) rotate(0);
            right: 35px;
            width: 18px;
            height: 18px;
            background-image: url(${require('./../../../themes/images/icons/ic-arrow-accordion-close.svg')});
            background-size: contain;
            background-repeat: no-repeat;
            background-color: ${VARIABLES.COLORS.TRANSPARENT};
            border-radius: 0;
          }
  
          /* Open content */
          &[aria-expanded='true'] {
            background-color: ${VARIABLES.COLORS.BLUE_2};
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            color: ${VARIABLES.COLORS.WHITE};
  
            &:before {}
  
            &:after {
              transform: translate(0, -50%) rotate(0);
              background-image: url(${require('./../../../themes/images/icons/ic-arrow-accordion-open.svg')});
            }
          }  
        }
      }

      /* Content */
      .accordion__panel {
        ${TYPOGRAPHYS.FIRST.REGULAR.FS_14};
        padding: 15px 35px 50px;
        background-color: ${VARIABLES.COLORS.WHITE};
        color: ${VARIABLES.COLORS.GRAY_3};

        &.accordion__panel--hidden {}
      }
    }

    /* Estimator Page */
    &.is-estimator {
      /* Container */
      .accordion {
        border: none;
      }

      /* List */
      .accordion__panel {
        margin-bottom: 5px;
        border-bottom: none;

        &:last-child {
          margin-bottom: 0;
        }
      }

      /* Button */
      .accordion__heading {
        ${TYPOGRAPHYS.FIRST.REGULAR.FS_18};
        transition: ${VARIABLES.TRANSITIONS.DEFAULT};
        background-color: ${VARIABLES.COLORS.BLUE_2};
        color: ${VARIABLES.COLORS.WHITE};
        line-height: 1;

        .accordion__button {
          padding: 13px 80px 13px 35px;
          &:before {
            display: none;
          }

          &:after {
            transform: translate(0, -50%) rotate(0);
            right: 35px;
            width: 18px;
            height: 18px;
            background-image: url(${require('./../../../themes/images/icons/ic-arrow-accordion-close-white.svg')});
            background-size: contain;
            background-repeat: no-repeat;
            background-color: ${VARIABLES.COLORS.TRANSPARENT};
            border-radius: 0;
          }

          /* Open content */
          &[aria-expanded='true'] {
            background-color: ${VARIABLES.COLORS.BLUE_2};
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            color: ${VARIABLES.COLORS.WHITE};
  
            &:before {}
  
            &:after {
              transform: translate(0, -50%) rotate(0);
              background-image: url(${require('./../../../themes/images/icons/ic-arrow-accordion-open.svg')});
            }
          }
        }
      }

      /* Content */
      .accordion__panel {
        ${TYPOGRAPHYS.FIRST.REGULAR.FS_14};
        background-color: ${VARIABLES.COLORS.WHITE};
        color: ${VARIABLES.COLORS.GRAY_3};
      }
    }

    /* Media queries
    ------------------------------- */
  }
`
