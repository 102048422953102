import {
  injectGlobal
} from 'styled-components'
import {
  default as TYPOGRAPHYS
} from './../../../themes/styles/bases/typographys'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'

injectGlobal`
  @keyframes notification {
    0%   {
      transform: scale(0.1);
      opacity: 0;
    }

    75%  {
      transform: scale(1);
      opacity: 0.5;
    }

    100%  {
      transform: scale(1.2);
      opacity: 0;
    }
  }

  .section-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    /* Modifiers
    ------------------------------- */
    &.is-cursor {
      cursor: pointer;
    }

    &.is-position-relative {
      position: relative;
    }
    
    &.is-position-absolute {
      position: absolute;
    }

    &.is-nowrap {
      flex-wrap: nowrap;
    }

    &.is-overflow-hidden {
      overflow: hidden;
    }

    &.is-display-block {
      display: block;
    }

    /* Home */
    &.is-menu-home {
      position: relative;
      z-index: ${VARIABLES.Z_INDEXS.LV_2};
      margin-top: -45px;
    }

    &.is-zindex-lv1 {
      position: relative;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
    }

    /* Login */
    &.is-login-container {
      margin-top: 8vh;
      margin-bottom: 6vh;
    }

    &.is-login-logo {
      margin-bottom: 13vh;
    }

    &.is-login-phone-logo {
      margin-bottom: 11vh;
    }

    &.is-login-forgotpassword {
      margin-bottom: 11vh;
    }

    &.is-login-phone-forgotpassword {
      margin-bottom: 8vh;
    }

    &.is-login-back {
      margin-bottom: 6vh;
    }

    /* Splash screen */
    &.is-splashscreen-logo {
      transform: translate(-50%, -50%);
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: calc(50% - 60px);
      left: 50%;
    }

    &.is-splashscreen-content {}


    &.is-register-floating-label {
      position: relative;

      .is-floating-label,
      .is-floating-label-initial {
        pointer-events: none;
        position: absolute;
        z-index: ${VARIABLES.Z_INDEXS.LV_1};
        left: 0;
      }

      .is-floating-label {
        ${TYPOGRAPHYS.FIRST.REGULAR.FS_12};
        top: -20px;

        .text-base {
          ${TYPOGRAPHYS.FIRST.REGULAR.FS_12};
        }
      }

      .is-floating-label-initial {
        top: -1px;
      }

      .is-icon-password {
        position: absolute;
        right: 0px;
      }
    }

    &.is-floating-placeholder {
      ${TYPOGRAPHYS.FIRST.REGULAR.FS_15};
      pointer-events: none;
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 0;
      left: 0;
      width: calc(100% - 45px);
      padding-right: 10px;
      padding-left: 10px;
      background-color: ${VARIABLES.COLORS.WHITE};
      color: ${VARIABLES.COLORS.GRAY_5};
    }

    &.is-agree-terms {
      position: fixed;
      bottom: 0;
      width: 100%;
      max-width: ${VARIABLES.WEBSITE_WIDTH_PX};
      margin-right: auto;
      margin-left: auto;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.33);
    }

    &.is-header {
      font-size: 13px;
      position: fixed;
      top: 0;
      z-index: ${VARIABLES.Z_INDEXS.LV_2};
      -webkit-box-shadow: 0px 2px 13px 0px rgba(175,180,188,1);
      -moz-box-shadow: 0px 2px 13px 0px rgba(175,180,188,1);
      box-shadow: 0px 2px 13px 0px rgba(175,180,188,1);
    }

    &.is-shadow {
      -webkit-box-shadow: 0px 2px 11px -5px rgba(148,190,211,1);
      -moz-box-shadow: 0px 2px 11px -5px rgba(148,190,211,1);
      box-shadow: 0px 2px 11px -5px rgba(148,190,211,1);
      border-radius: 10px;
    }

    &.is-my-product,
    &.is-my-project {
      -webkit-box-shadow: 0px 2px 11px -5px rgba(148,190,211,1);
      -moz-box-shadow: 0px 2px 11px -5px rgba(148,190,211,1);
      box-shadow: 0px 2px 11px -5px rgba(148,190,211,1);
    }

    &.is-product-catalog,
    &.is-reward-catalog {
      overflow: hidden;
      position: relative;
      border-radius: 3px;
    }

    &.is-product-catalog-name,
    &.is-reward-catalog-name {
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      bottom: 0;
      left: 0;
      width: 100%;
    }

    &.is-product-subcatalog {
      overflow: hidden;
      position: relative;
      border-radius: 6px;
      box-shadow: ${VARIABLES.COLORS.SHADOW_2};
    }

    &.is-project-field {
      position: relative;
      .is-top-label {
        position: absolute;
        z-index: ${VARIABLES.Z_INDEXS.LV_1};
        top: -12px;
        left: 20px;
        padding: 5px;
        background: white;
      }
    }

    &.is-side-menu {
      position: relative;
      z-index: ${VARIABLES.Z_INDEXS.LV_2};
      position: fixed;
      top: 0;
      left: 0;

      .is-side-menu-content {
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
        box-shadow: ${VARIABLES.COLORS.SHADOW_1};

        .is-close-side-menu {
          position: absolute;
          z-index: ${VARIABLES.Z_INDEXS.LV_3};
          top: 25px;
          left: 255px;
        }
      }
    }

    &.is-search-placeholder {
      top: 12px;
      left: 20px;
    }

    /* TODO: recheck */
    &.is-content-detail {
      top: 350px;
      position: absolute;
    }

    &.is-user-menu {
      top: 80px;
      right: 20px;
      position: absolute;
    }

    &.is-news-promotions-content {
      width: calc(100vw - 188px);
    }

    &.is-order-inquiry-content {
      width: calc(100vw - 160px);
    }

    &.is-warranty-content {
      width: calc(100vw - 120px);
    }

    &.is-purchase-history-content {
      width: calc(100vw - 110px);
    }

    &.is-reward-catalog-content {
      width: calc(100vw - 195px);
    }

    &.is-version-text {
      bottom: 50px;
      left: 0;
    }

    &.is-version-text-side-menu {
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      width: auto;
      bottom: 40px;
      left: 170px;
    }

    &.is-social-buttons {
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_2};
      bottom: -19px;
      right: 20px;
    }

    &.is-dealer-card {
      width: 100%;
      max-width: ${VARIABLES.WEBSITE_WIDTH_PX};
      background-color: ${VARIABLES.COLORS.WHITE};
      box-shadow: ${VARIABLES.COLORS.SHADOW_1};
      &.is-mini {
        margin-top: 10px;
        .is-dealer-card-img {
          padding-bottom: 75px;
        }
      }
    }

    &.is-dealer-list {
      position: absolute;
      bottom: 20px;
      left: 0;
      transform: translateX(-50%);
      position: fixed;
      left: 50%;
      max-width: ${VARIABLES.WEBSITE_WIDTH_PX};
    }

    &.is-dealer-detail-card {
      width: 100%;
      background-color: ${VARIABLES.COLORS.WHITE};
      box-shadow: ${VARIABLES.COLORS.SHADOW_1};
    }

    &.is-dealer-card-number {
      top: 0;
      left: 15px;
      z-index: ${VARIABLES.Z_INDEXS.LV_2};
    }

    &.is-dealer-card-distance {
      bottom: 8px;
      left: 15px;
      z-index: ${VARIABLES.Z_INDEXS.LV_2};
    }

    &.is-carousel-content {
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgb(14,30,49);
      background: -moz-linear-gradient(180deg, rgba(14,30,49,0.89) 0%, rgba(13,54,103,0.36) 70%, rgba(116,128,155,0) 100%);
      background: -webkit-linear-gradient(180deg, rgba(14,30,49,0.89) 0%, rgba(13,54,103,0.36) 70%, rgba(116,128,155,0) 100%);
      background: linear-gradient(180deg, rgba(14,30,49,0.89) 0%, rgba(13,54,103,0.36) 70%, rgba(116,128,155,0) 100%);
    }

    &.is-carousel-content-logo {
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &.is-fade-gradient {
      position: fixed;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      bottom: 0;
      left: 0;
      width: 100%;
      height: 145px;
      background: rgb(255,255,255);
      background: -moz-linear-gradient(180deg, rgba(255,255,255,0.17) 0%, rgba(255,255,255,1) 100%);
      background: -webkit-linear-gradient(180deg, rgba(255,255,255,0.17) 0%, rgba(255,255,255,1) 100%);
      background: linear-gradient(180deg, rgba(255,255,255,0.17) 0%, rgba(255,255,255,1) 100%);
    }

    &.is-gradient-blue {
      background: rgb(40,80,110);
      background: -moz-linear-gradient(45deg, rgba(40,80,110,1) 0%, rgba(48,132,188,1) 100%);
      background: -webkit-linear-gradient(45deg, rgba(40,80,110,1) 0%, rgba(48,132,188,1) 100%);
      background: linear-gradient(45deg, rgba(40,80,110,1) 0%, rgba(48,132,188,1) 100%);
    }

    &.reward-cat-img {
      position: relative;
      .reward-cat-img-label {
        position: absolute;
        z-index: ${VARIABLES.Z_INDEXS.LV_1};
        top: -5px;
        right: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        background-color: ${VARIABLES.COLORS.BLUE_2};
        border-radius: 50%;
        font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.BOLD};
        font-size: 7px;
        line-height: 1;
        color: ${VARIABLES.COLORS.WHITE};
      }
    }

    &.is-estimator-image-item {
      &:last-child {
        border: none;
      }
    }

    &.is-version-number {
      opacity: 0.35;
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      bottom: 10px;
      left: 15px;

      &.is-version-number-right {
        right: 15px;
        left: auto;
      }
    }

    &.is-floating-footer-button {
      position: fixed;
      z-index: ${VARIABLES.Z_INDEXS.LV_2};
      bottom: 0;
      left: 0;
      width: 100%;
      height: 147px;
      padding-bottom: 7vh;
      background: rgb(255,255,255);
      background: -moz-linear-gradient(180deg, rgba(255,255,255,0.17) 0%, rgba(255,255,255,1) 100%);
      background: -webkit-linear-gradient(180deg, rgba(255,255,255,0.17) 0%, rgba(255,255,255,1) 100%);
      background: linear-gradient(180deg, rgba(255,255,255,0.17) 0%, rgba(255,255,255,1) 100%);
    }

    &.is-estimator-template {
      padding-top: 60px;
      padding-bottom: 60px;
      background-color: ${VARIABLES.COLORS.WHITE};
    }

    &.is-estimator-template-container {
      width: 1122px;           }

    &.is-otp-incorrect-message {
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 70px;
      right: 0;
      left: 0;
    }

    &.is-public-back-button {
      transform: translateX(-50%);
      position: fixed;
      z-index: ${VARIABLES.Z_INDEXS.LV_2};
      top: 0;
      left: 50%;
    }

    &.is-notification-balloon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 5px;
      right: 5px;
      width: 28px;
      height: 28px;

      &:before,
      &:after {
        content: ' ';
        display: block;
      }

      &:before {
        position: relative;
        z-index: ${VARIABLES.Z_INDEXS.LV_2};
        width: 10px;
        height: 10px;
        background-color: ${VARIABLES.COLORS.RED_3};
        border-radius: 50%;
      }

      &:after {
                position: absolute;
        z-index: ${VARIABLES.Z_INDEXS.LV_1};
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${VARIABLES.COLORS.RED_3};
        border-radius: 50%;
        animation: notification;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }

    &.is-phone-id {
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 0;
      left: 32px;
      width: auto;
    }

    &.is-account-info {
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_2};
      top: 62px;
      left: 81px;
      width: auto;
    }

    &.is-certified {
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_2};
      top: 60px;
      right: 20px;
      width: auto;
    }

    &.is-bg-logo {
      background-image: url(${require('./../../../themes/images/shareds/bg-menu-logo.png')});
      background-repeat: no-repeat;
      background-position: right top;
      background-size: 370px 120px;
    }

    &.is-gold-card {
      display: flex;
      background-color: #D9BC72;
      border-radius: 85px;
      width: 170px;
      height: 170px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      right: -77px;
      bottom: -97px;
    }

    &.is-footer-expire-luckydraw {
      background: linear-gradient(180deg, #3D8CC4 0%, #1A5C89 100%);
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.20);
    }

    &.is-customer-profile {
      background: linear-gradient(180deg, #3D8CC4 0%, #1A5C89 100%);
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.20);
    }

    /* Media queries
    ------------------------------- */
    @media (max-height: 736px) {
      &.is-login-container {
        margin-top: 9vh;
        margin-bottom: 2vh;
      }

      &.is-login-logo {
        margin-bottom: 9vh;
      }

      &.is-login-forgotpassword {
        margin-bottom: 8vh;
      }

      &.is-login-phone-forgotpassword {
        margin-bottom: 6vh;
      }
    }

    &.is-news-promotions-content {
      @media (min-width: 415px) {
        width: 209px; /* 226 */
      }
    }

    &.is-order-inquiry-content {
      @media (min-width: 415px) {
        width: 240px;
      }
    }

    &.is-warranty-content {
      @media (min-width: 415px) {
        width: 290px;
      }
    }

    &.is-purchase-history-content {
       @media (min-width: 415px) {
        width: 300px;
      }
    }

    &.is-reward-catalog-content {
      @media (min-width: 415px) {
        width: 220px;
      }
    }

    &.is-splashscreen-content {

      @media (max-height: 640px) {
        margin-bottom: 30px;
      }

      @media (min-height: 641px) and
             (max-height: 736px) {
        margin-bottom: 35px;
      }
    }

    &.is-message-page {
      .text-base {
        &.is-message-large {
          @media (max-height: 640px) {
            ${TYPOGRAPHYS.FIRST.REGULAR.FS_16};
          }
        }

        &.is-message-medium {
          @media (max-height: 640px) {
            ${TYPOGRAPHYS.FIRST.REGULAR.FS_12};
          }
        }

        &.is-message-bold-medium {
          @media (max-height: 640px) {
            ${TYPOGRAPHYS.FIRST.BOLD.FS_12};
          }
        }
      }

      .section-base {
        &.is-message-container {
          @media (max-height: 640px) {
            max-width: 228px;
          }
        }
      }

      .image-base {
        &.is-message-icon {
          @media (max-height: 640px) {
            width: 169px;
            padding-bottom: 120px;
          }
        }
      }
    }

    &.is-terms-and-condition {
      ul, ul li {
        list-style-position: inside;
        list-style-type: disc;
        word-break: break-word;
      }

      ol,
      ol li {
        word-break: break-word;
        list-style-position: inside;
        list-style-type: decimal;
      }
    }
  }
`
