import {
  injectGlobal
} from 'styled-components'
import {
  default as TYPOGRAPHYS
} from './../../../themes/styles/bases/typographys'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'

injectGlobal`
  .button-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    /* Modifiers
    ------------------------------- */
    &.is-border {
      border-bottom: 1px solid ${VARIABLES.COLORS.WHITE};
    }

    &.is-border-blue {
      border-bottom: 1px solid ${VARIABLES.COLORS.BLUE_2};
    }

    &.is-icon-left {
      position: relative;

      .button-icon-base {
        transform: translateY(-50%);
        position: absolute;
        z-index: ${VARIABLES.Z_INDEXS.LV_1};
        top: 50%;
        left: 20px;
      }
    }

    &.is-icon-right {
      position: relative;

      .button-icon-base {
        transform: translateY(-50%);
        position: absolute;
        z-index: ${VARIABLES.Z_INDEXS.LV_1};
        top: 50%;
        right: 20px;
      }
    }

    &.is-icon-front {
      .button-icon-base {
        padding-right: 10px;
      }
    }

    &.is-gradient-blue {
      background: rgb(40,80,110);
      background: -moz-linear-gradient(45deg, rgba(40,80,110,1) 0%, rgba(48,132,188,1) 100%);
      background: -webkit-linear-gradient(45deg, rgba(40,80,110,1) 0%, rgba(48,132,188,1) 100%);
      background: linear-gradient(45deg, rgba(40,80,110,1) 0%, rgba(48,132,188,1) 100%);
    }

    /* Fix render gradient in htmltocanvas */
    &.is-gradient-blue-image {
      background: url(${require('./../../../themes/images/shareds/bg-button-gradient-blue.jpg')});
      background-size: 100%;
    }

    &.is-shadow {
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.16);
    }

    &.is-add-project {
      position: fixed;
      bottom: 7vh;
      z-index: ${VARIABLES.Z_INDEXS.LV_2};
    }

    &.is-content-detail-back {
      position: absolute;
      top: 70px;
      left: 20px;
      z-index: ${VARIABLES.Z_INDEXS.LV_2};
      box-shadow: ${VARIABLES.COLORS.SHADOW_3};
    }

    &.is-register-image-icon {
      position: absolute;
      top: 4px;
      right: 70px;
      z-index: ${VARIABLES.Z_INDEXS.LV_2};
    }

    &.is-content-detail-share {
      position: absolute;
      top: -20px;
      right: 20px;
      z-index: ${VARIABLES.Z_INDEXS.LV_2};
    }


    &.is-content-detail-del {
      position: absolute;
      top: -20px;
      right: 70px;
      z-index: ${VARIABLES.Z_INDEXS.LV_2};
    }


    &.is-clear-search {
      position: absolute;
      top: 10px;
      right: 15px;
      z-index: ${VARIABLES.Z_INDEXS.LV_2};
    }

    &.is-text-left {
      .button-body-base {
        justify-content: left;
      }
    }

    &.is-gps-dealer-result {
      position: absolute;
      top: 60px;
      right: 20px;
      z-index: ${VARIABLES.Z_INDEXS.LV_2};
      &.is-map-dealer-list {
        bottom: 10px;
        right: 20px;
        top: inherit;
      }
    }

    &.is-coming-soon,
    &.is-coming-soon-thumbnail {
      pointer-events: none;
      position: relative;

      &:after {
        content: 'Coming soon';
        position: absolute;
        z-index: ${VARIABLES.Z_INDEXS.LV_1};
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.85);
        color: ${VARIABLES.COLORS.BLUE_2};
      }
    }

    &.is-coming-soon {
      &:after {
        ${TYPOGRAPHYS.FIRST.MEDIUM.FS_10};
        border-radius: 9px;
      }
    }

    &.is-coming-soon-thumbnail {
      &:after {
        ${TYPOGRAPHYS.FIRST.MEDIUM.FS_12};
        border-radius: 3px;
      }
    }

    &.is-menu-bg-logo {
      background-image: url(${require('./../../../themes/images/shareds/bg-menu-logo.png')});
      background-repeat: no-repeat;
      background-position: right top;
      background-size: 95px 64px;

      .button-body-base {
        flex-direction: column;
      }
    }

    &.is-banner-lucky-draw {
      .button-body-base {
        display: none;
      }
    }

    /* Media queries
    ------------------------------- */
  }
`
