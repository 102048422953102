import { injectGlobal } from "styled-components";
import { default as VARIABLES } from "./../../../themes/styles/bases/variables";

injectGlobal`
  html {
    touch-action: manipulation; /* remove the 300-350ms tap delay */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    overflow-y: auto;
    text-rendering: optimizeLegibility;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${VARIABLES.COLORS.BLUE_5};
  }

  * {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal; /* Reset for Embed font */
  }

  a {
    text-decoration: none;

    &:hover {}

    &:focus {}

    &:active {}

    &:visited {}
  }

  address {
    font-style: normal;
  }

  button {
    &:focus {
      outline: none;
    }
  }

  fieldset {
    border: none;
  }

  legend {
    width: 100%;
  }

  input,
  button,
  select,
  textarea {
    /* Reset fonts for relevant elements */
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;

    &:focus {
      outline: none;
    }
  }

  input {
    outline: none;
  }

  picture {
    display: inline-block;
  }

  picture,
  img {
    vertical-align: middle;
  }

  hr {
    margin-top: 0;
    margin-bottom: 0;
    border: 0;
    border-top: 1px solid;
  }

  /*
  
  
  
  
  
  */
  [role='button'] {
    cursor: pointer;
  }

  /*
  
  
  
  */
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  /*
  
  
  
  */
  .sr-only-focusable {
    &:active,
    &:focus {
      position: static;
      width: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      clip: auto;
    }
  }
`;
