import { injectGlobal } from "styled-components";

// * Home

injectGlobal`
  /* Home
  ============================================================ */
  .home {
    .XXXXX {
      /* Style */
    }
  }

  select {
    background: transparent;
    border: 0;
    color: white;
  }

  #select-regis-flag{
    color: black;
  }
`;
